<template>
    <div :class="[
        'flag',
        `size-${size}`,
        { 'flag-border-radius': hasBorderRadius },
        { 'flag-border': hasBorder },
        { 'flag-drop-shadow': hasDropShadow },
        gradient,
        className
    ]">
        <img :src="imageUrl">
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Flag',
    computed: {
        imageUrl() {
            let code = this.code;

            if (code === 'en') {
                code = "gb";
            }

            if (code === 'et') {
                code = "ee";
            }

            if (code === 'ru') {
                code = "ru";
            }

            return `/images/flags/${code}.svg`;
        }
    },
    props: {
        size: {
            type: String,
            default: 'm',
            validator: value => (
                ['s', 'm', 'l'].indexOf(value) !== -1
            ),
        },
        code: {
            type: String,
            default: '528'
        },
        hasDropShadow: {
            type: Boolean,
            default: false,
        },
        hasBorder: {
            type: Boolean,
            default: true
        },
        hasBorderRadius: {
            type: Boolean,
            default: true,
        },
        gradient: {
            type: String,
            validator: value => (
                ['top-down', 'real-linear', 'real-circular'].indexOf(value) !== -1
            ),
        },
        className: {
            type: String
        }
    },
})
</script>

<style scoped lang="scss">
@mixin before-styling {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    mix-blend-mode: overlay;
    box-sizing: border-box;
}

.flag {
    display: inline-block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;

    &.size {
        &-s {
            width: 16px;
            height: 12px;

            &.flag-drop-shadow {
                box-shadow: 0 0 1px 0.5px rgba(0, 0, 0, 0.10);
            }

            &.flag-border-radius {
                border-radius: 1px;
            }
        }

        &-m {
            width: 20px;
            height: 15px;

            &.flag-drop-shadow {
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.10);
            }

            &.flag-border-radius {
                border-radius: 1.5px;
            }
        }

        &-l {
            width: 32px;
            height: 24px;

            &.flag-drop-shadow {
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.10);
            }

            &.flag-border-radius {
                border-radius: 2px;
            }
        }
    }

    &.flag-border {
        &::before {
            @include before-styling();
            border: 1px solid rgba(0, 0, 0, .5);
            mix-blend-mode: overlay;
        }
    }

    &.flag-border-radius {
        &::before {
            @include before-styling();
            border-radius: 1px;
        }
    }

    &.top-down {
        &::before {
            @include before-styling();
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 2%, rgba(255, 255, 255, 0.70) 100%);
        }
    }

    &.real-linear {
        &::before {
            @include before-styling();
            background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.20) 0%, rgba(39, 39, 39, 0.22) 11%, rgba(255, 255, 255, 0.30) 27%, rgba(0, 0, 0, 0.24) 41%, rgba(0, 0, 0, 0.55) 52%, rgba(255, 255, 255, 0.26) 63%, rgba(0, 0, 0, 0.27) 74%, rgba(255, 255, 255, 0.30) 100%);
        }
    }

    &.real-circular {
        &::before {
            @include before-styling();
            background: radial-gradient(rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.24) 11%, rgba(0, 0, 0, 0.55) 17%, rgba(255, 255, 255, 0.26) 22%, rgba(0, 0, 0, 0.17) 27%, rgba(255, 255, 255, 0.28) 31%, rgba(255, 255, 255, 0.00) 37%, 50% 36%) center calc(50% - 8px) / 600% 600%,
                radial-gradient(rgba(255, 255, 255, 0.30) 25%, rgba(0, 0, 0, 0.24) 48%, rgba(0, 0, 0, 0.55) 61%, rgba(255, 255, 255, 0.26) 72%, rgba(0, 0, 0, 0.17) 80%, rgba(255, 255, 255, 0.28) 88%, rgba(255, 255, 255, 0.30) 100%, 50% 123%) center calc(50% - 8px) / 600% 600%;
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
