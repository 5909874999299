<template>
    <component
        :is="as"
        :href="href"
        :class="[linkStyle, 'flex items-center transition-colors ease-out duration-200 text-base font-medium']"
    >
        <slot />
    </component>
</template>

<script>
import { Link } from '@inertiajs/vue3';

import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        Link,
    },

    props: {
        as: {
            default: () => Link,
        },
        navbarStyle: String,
        href: String,
        active: Boolean,
    },

    computed: {
        linkStyle() {
            if (this.navbarStyle === 'dark') {
                return this.active ? 'text-white' : 'text-gray-300 hover:text-gray-100';
            }

            return this.active ? 'text-gray-900' : 'text-gray-500 hover:text-gray-800';
        },
    },
})
</script>
