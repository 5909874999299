<template>

    <Head :title="title">
        <slot />
    </Head>
</template>

<script>
import { computed } from 'vue';
import { Head, usePage } from '@inertiajs/vue3'

import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: String,
    },

    components: {
        Head,
    },

    setup(props) {
        const title = computed(() => {
            const title = props.title;
            const appName = usePage().props.project.name;

            return title ? `${title} | ${appName}` : appName;
        });

        return { title };
    },
})
</script>
